import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'customer-calling',
    loadChildren: () => import('./customer-calling/customer-calling.module').then( m => m.CustomerCallingPageModule)
  },
  {
    path: 'customer-meeting',
    loadChildren: () => import('./customer-meeting/customer-meeting.module').then( m => m.CustomerMeetingPageModule)
  },
  {
    path: 'calling',
    loadChildren: () => import('./agent-calling/agent-calling.module').then( m => m.AgentCallingPageModule)
  },
  {
    path: 'agent-meeting',
    loadChildren: () => import('./agent-meeting/agent-meeting.module').then( m => m.AgentMeetingPageModule)
  },
  {
    path: 'customer-end',
    loadChildren: () => import('./customer-end/customer-end.module').then( m => m.CustomerEndPageModule)
  },
  {
    path: 'agent-end',
    loadChildren: () => import('./agent-end/agent-end.module').then( m => m.AgentEndPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
